<template>
  <div class="bg_color"
  >
    <v-img
          gradient="to bottom right, #C4DDFF 5%, transparent 80%"
          style="background-size: cover"
          src="../assets/images/login-bg-bromo.jpeg"
    >
<!--      gradient="to bottom right, blue 10%, transparent 90%"-->
<!--      style="background-size: cover"-->
<!--      src="../assets/images/motif_batik.png"-->
      <v-form class="mt-8 mb-2">
        <v-card elevation="10"
                class="mx-auto rounded-lg"
                max-width="500"
                color="rgb(0, 153, 255, 0.1)"
        >
          <v-card-title class="black--text">
            <v-spacer/>
            LOGIN
            <v-spacer/>
          </v-card-title>
          <v-card-text>
            <v-text-field
                v-model="user.username"
                label="Username"
                hide-details="auto"
                dark
            ></v-text-field>
            <v-text-field
                type="password"
                label="Password"
                v-model="user.password"
                hide-details="auto"
                dark
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="ma-4">
            <v-spacer/>
            <v-btn
                color="primary"
                @click="handleLogin"
                width="200px"
            >Login</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-spacer></v-spacer>

      <!--      <v-footer-->
      <!--          color="rgb(0,0,0,0)"-->
      <!--          class="justify-center pl-0 footer_image" inset app-->
      <!--      >-->
      <!--      </v-footer>-->
    </v-img>
  </div>

</template>

<script>
import User from '../models/user';

export default {
  name: 'LoginView',
  components: {},
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
              () => {
                this.$router.push('/dashboard');
              },
              error => {
                this.loading = false;
                this.message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
              }
          );
        }
      });
    }
  }
};
</script>
<style scoped>
  .bg_color{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /*background-color: rgba(139, 172, 224, 0.99);*/
    background-image: linear-gradient(to bottom right, lightskyblue 5%, transparent 90%);
  }
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /*background: url( 'https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg') no-repeat center center;*/
    background-size: cover;
    /*background-image: url( 'https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg'), linear-gradient(to bottom right, #f7d96a 10%, transparent 90%);*/
    background-image: linear-gradient(to bottom right, floralwhite 50%, transparent 90%);
    /*transform: scale(1.1);*/
  }
  /*background: url( 'https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg') no-repeat center center;*/
  /*background-color: lightyellow;*/
  /*background: url( 'https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg') no-repeat center center;*/

  .footer_image{
    background-image: url('https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg'), linear-gradient(#eb01a5, #d13531);
    background-color: rgba(0, 0, 0, 0.5);
  }

</style>

